.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  background-image: url("/images/RICA1.png");
  background-size: cover;
  background-position: center;
}

.content {
  color: white;
  margin-left: 300px;
  margin-top: 450px;
}

.content h1 {
  background: linear-gradient(to right, #61e08c, #2376f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 5.1rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.buttons {
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.buttons button {
  background-color: #13ac92;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  transition: background-color 0.3s ease;
  width: 225px;
}

.buttons button:hover {
  background-color: #0056b3;
}

@media (max-width: 1024px) {
  .content {
    margin-left: 50px;
    margin-top: 250px;
  }

  .content h1 {
    font-size: 4rem;
  }

  .buttons button {
    width: 225px;
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .container {
    background-position: 680px;
  }

  .content {
    margin-left: 20px;
    margin-top: 150px;
  }

  .content h1 {
    font-size: 2.5rem;
  }

  .buttons {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }

  .buttons button {
    width: 225px;
    padding: 8px 15px;
    font-size: 1.5rem;
  }
}
